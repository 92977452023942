import React, { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import Map, { Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pin from "../Comnponents/pin";
import data from "../data.json";
import geodata from "../geodata.json";
import Carousel from "../Comnponents/Carousel";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Stack,
  Divider,
  Drawer,
  DrawerBody,
  Text,
  Button,
  Heading,
  SimpleGrid,
  Center,
  Box,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  GridItem,
  Grid,
} from "@chakra-ui/react";

import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

function Home() {
  const params = new URLSearchParams(document.location.search);
  let [searchParams, setSearchParams] = useSearchParams();
  const urlLat = params.get("lat");
  const urlLon = params.get("lon");
  const urlOrg = params.get("org");
  console.log(urlOrg);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [sidedata, setSidedata] = useState(null);
  const [value, setValue] = React.useState(0);
  const mapRef = useRef(null);
  const notify = () => toast("Link copied to clipboard!");

  useEffect(() => {
    urlLat ? showInfo(data[urlOrg - 1]) : console.log("null");
  }, []); //

  function showInfo(info) {
    setValue(info.index);
    onOpen();
    setSidedata(info);
    setSearchParams(
      "?lat=" + info.Lat + "&lon=" + info.Lon + "&org=" + info.index
    );
  }
  function nextOrg(val) {
    if (val <= 25) {
      setValue(val + 1);
      setSearchParams(
        "?lat=" + data[val].Lat + "&lon=" + data[val].Lon + "&org=" + (val + 1)
      );
      setSidedata(data[val]);
      mapRef.current?.flyTo({
        center: [data[val].Lon, data[val].Lat],
        duration: 2000,
        zoom: 9,
      });
    }
  }

  function prevOrg(val) {
    if (val >= 2) {
      setValue(val - 1);
      setSidedata(data[val - 2]);
      setSearchParams(
        "?lat=" + data[val].Lat + "&lon=" + data[val].Lon + "&org=" + (val - 1)
      );
      mapRef.current?.flyTo({
        center: [data[val - 2].Lon, data[val - 2].Lat],
        duration: 2000,
        zoom: 9,
      });
    }
  }

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken="pk.eyJ1IjoiYnVsYm85MiIsImEiOiJjbDQzMzZhMTkweHRtM2tvNjIwaXR3eGlyIn0.MUIBKp8y3bOHeb7WQBmPSw"
      initialViewState={{
        latitude: urlLat ? urlLat : 0,
        longitude: urlLon ? urlLon : 0,
        zoom: urlLon ? 12 : 2,
      }}
      interactiveLayerIds={[geodata]}
      style={{ width: "100vw", height: "100vh" }}
      mapStyle="mapbox://styles/mapbox/light-v9"
    >
      <Source type="geojson" data={geodata}>
        <Layer
          id="data"
          type="fill"
          paint={{
            "fill-color": "green",
            "fill-opacity": 0.2,
          }}
        />
      </Source>
      {data.map((info, index) => {
        return (
          <Marker
            key={index}
            longitude={info.Lon}
            latitude={info.Lat}
            anchor="bottom"
            onClick={() => showInfo(info)}
          >
            <Pin image={info.logo} />
          </Marker>
        );
      })}

      {sidedata ? (
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
          size={"md"}
          backgroundImage="url('../logo/bg.png')"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
        >
          <DrawerOverlay />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader mt="10">
              <SimpleGrid columns={3} spacing={10}>
                <Box>
                  <Button onClick={(e) => prevOrg(value)}> Prev </Button>
                </Box>
                <Box>
                  <Center>
                    <Heading as="h4" size="lg">
                      {value}
                    </Heading>
                  </Center>
                </Box>
                <Box justifySelf={"end"}>
                  <Button onClick={(e) => nextOrg(value)}> Next </Button>
                </Box>
              </SimpleGrid>
              <Box>
                <Center>
                  <Heading as="h4" size="xl">
                    {sidedata.Name}
                  </Heading>
                </Center>
              </Box>

              <Divider my="15px" />

              <Heading as="h4" size="lg">
                {sidedata.Country}
              </Heading>
            </DrawerHeader>

            <DrawerBody>
              <Stack spacing={3}>
                {/* <Image boxSize="80%" objectFit="cover" src={sidedata.img} /> */}
                <Carousel
                  img={sidedata.img}
                  img2={sidedata.img2}
                  img3={sidedata.img3}
                />
                <Box maxH={"30vh"} overflow={"scroll"}>
                  <Heading as="h4" size="lg" mb="10px">
                    Description
                  </Heading>
                  <Text fontSize="sm">{sidedata.Desc}</Text>
                </Box>
                <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                  <GridItem>
                    <Link to={"/gallery"} state={{ state: sidedata }}>
                      <Button colorScheme="green" mt="30px">
                        Org Gallery
                      </Button>
                    </Link>
                  </GridItem>
                  <GridItem>
                    <Button colorScheme="blue" mt="30px">
                      <a href={sidedata.website} target="_blank">
                        Org Website
                      </a>
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button colorScheme="yellow" mt="30px" onClick={notify}>
                      <CopyToClipboard text={window.location.href}>
                        <p>Share Page</p>
                      </CopyToClipboard>
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Link to={"/constellation"}>
                      <Button colorScheme="green" mt="30px">
                        Constellation
                      </Button>
                    </Link>
                  </GridItem>
                </Grid>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        ""
      )}
    </Map>
  );
}

export default Home;
