import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import * as THREE from "three";
import moontexture from "../assets/moon.jpg";
import moonNormal from "../assets/normal.jpg";
import startexture from "../assets/star.png";
import data from "../assets/data5.json";

const Star = ({ position, info, color }) => {
  const [hovered, setHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const starRef = useRef();
  const starTexture = new THREE.TextureLoader().load(startexture);

  useEffect(() => {
    let timeout;
    if (hovered) {
      timeout = setTimeout(() => setShowTooltip(true), 300); // Show after 300ms
    } else {
      clearTimeout(timeout);
      setShowTooltip(false); // Hide immediately when not hovered
    }
    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, [hovered]);

  // Update the star to face the camera
  useFrame(({ camera }) => {
    if (starRef.current) {
      starRef.current.lookAt(camera.position); // Orient the plane toward the camera
    }
  });

  return (
    <mesh
      ref={starRef}
      position={position}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      {/* Plane geometry for the star */}
      <planeGeometry args={[1.5, 1.5]} />
      <meshBasicMaterial
        map={starTexture}
        transparent={true}
        side={THREE.DoubleSide}
      />

      {/* Tooltip */}
      {hovered && (
        <Html center>
          <div
            style={{
              background: "#202035",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              border: `1px solid ${color}`,
              pointerEvents: "none",
            }}
          >
            {info}
          </div>
        </Html>
      )}
    </mesh>
  );
};

const Moon = () => {
  const moonRef = useRef();

  // Rotate the moon continuously
  useFrame(() => {
    if (moonRef.current) {
      moonRef.current.rotation.y += 0.005;
    }
  });

  const moonTexture = new THREE.TextureLoader().load(moontexture);
  const normalTexture = new THREE.TextureLoader().load(moonNormal);

  return (
    <mesh ref={moonRef}>
      <sphereGeometry args={[10, 32, 32]} />
      <meshStandardMaterial map={moonTexture} normalMap={normalTexture} />
    </mesh>
  );
};

const Scene = () => {
  // Generate random star positions and info
  const stars = data.map((item, i) => ({
    position: [
      (Math.random() - 0.5) * 200,
      (Math.random() - 0.5) * 200,
      (Math.random() - 0.5) * 200,
    ],
    info: `Stars of ${item.Name} ${item.Surname}, a ${item.category} supporter!`,
    color: item.color,
  }));

  return (
    <Canvas
      camera={{ position: [0, 0, 250], fov: 20 }}
      style={{ background: "black", height: "100vh" }}
    >
      <ambientLight intensity={0.5} />
      <pointLight position={[25, 50, 25]} intensity={1} />
      <OrbitControls
        enableDamping={true}
        dampingFactor={0.05}
        enableZoom={false}
      />
      <Moon />
      {stars.map((star, idx) => (
        <Star
          key={idx}
          position={star.position}
          info={star.info}
          color={star.color}
        />
      ))}
    </Canvas>
  );
};

function Constellation() {
  return <Scene />;
}

export default Constellation;
