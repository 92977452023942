import { Routes, Route, useNavigate } from "react-router-dom";

import Home from "../Pages/Home";
import Dispay from "../Pages/Dispay";
import Constellation from "../Pages/Constellation";
export default function RootNavigation() {
  let navigate = useNavigate();

  return (
    <Routes>
      <Route path="*" element={<Home title="Home" />} />
      <Route path="gallery" element={<Dispay title="Dispay" />} />
      <Route
        path="constellation"
        element={<Constellation title="Constellation" />}
      />
    </Routes>
  );
}
